
import axiosInstance, { endpoints } from '../utills/axios';



export const productNew = (bodyData) => {
  return axiosInstance.post(endpoints.products.new, bodyData)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const listProduct = (queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axiosInstance.get(`${endpoints.products.listAll}?${queryString}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const getProductDetails = (id) => {
  return axiosInstance.get(`${endpoints.products.details(id)}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};


export const listingFeaturedProducts = () => {
  return axiosInstance.get(`${endpoints.products.listingFeatured}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const deleteProduct = (id) => {
  return axiosInstance.delete(`${endpoints.products.delete(id)}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};