import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { styled } from "@mui/system";

// Styled Markdown container
const MarkdownContainer = styled("div")(({ theme }) => ({
  "& h1, h2, h3": {
    color: theme?.palette?.primary?.main || "#1976d2", // Fallback to default color
    fontWeight: "bold",
    marginBottom: theme?.spacing(2) || 16,
  },
  "& h1": {
    fontSize: "2rem",
  },
  "& h2": {
    fontSize: "1.5rem",
  },
  "& h3": {
    fontSize: "1.25rem",
  },
  "& p": {
    fontSize: "1rem",
    lineHeight: 1.8,
    marginBottom: theme?.spacing(2) || 16,
    color: theme?.palette?.text?.primary || "#333",
  },
  "& ul": {
    paddingLeft: theme?.spacing(4) || 32,
    marginBottom: theme?.spacing(2) || 16,
  },
  "& li": {
    marginBottom: theme?.spacing(1) || 8,
  },
  "& a": {
    color: theme?.palette?.primary?.main || "#1976d2",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& hr": {
    margin: `${theme?.spacing(3) || 24}px 0`,
    border: `0`,
    borderTop: `1px solid ${theme?.palette?.divider || "#ccc"}`,
  },
}));

const ContentComponent = ({ title, content }) => {
  return (
    <Box
      sx={{
        py: 6,
        background: "#f9f9f9",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mb: 4,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>

        {/* Markdown Content */}
        <MarkdownContainer>
          <ReactMarkdown>{content}</ReactMarkdown>
        </MarkdownContainer>
      </Container>
    </Box>
  );
};

export default ContentComponent;
