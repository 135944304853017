import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./App.css"; // Make sure to create and style this CSS file
import CardGrid from "../cards/CardGrid";
import Footer from "../footer/footer";
import About from "../about/About";
import { listingFeaturedProducts } from "../../api/product";
import { Box, CircularProgress } from "@mui/material";
import ContactusUs from "../contact/Contact";
import Social from "../social/Social";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HomePage = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getFeaturedCardData = async () => {
      try {
        const response = await listingFeaturedProducts();
        if (response.success) {
          setFeaturedProducts(response.featuredProducts);
        }
      } catch (error) {
        console.error("Error fetching featured products:", error);
      } finally {
        setLoading(false);
      }
    };

    getFeaturedCardData();
  }, []);

  const goToLearnMore = () => {
    navigate("/about-us"); // Navigate to the "About Us" page
  };

  const goToAllGames = () => {
    navigate("/all-games"); // Navigate to the "All Games" page
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ShortCodeGames | Unlock Game Development Secrets</title>
          <meta
            name="description"
            content="ShortCodeGames offers fully customizable game source codes, allowing you to create unique gaming experiences. Explore, learn, and build games effortlessly."
          />
          <meta
            name="keywords"
            content="game development, source code, game templates, customizable games, game creation, ShortCodeGames"
          />
          <meta name="author" content="ShortCodeGames Team" />
          <link rel="canonical" href="https://shortcodegames.com" />
        </Helmet>
      </HelmetProvider>
      <div className="background-container">
        <div className="header">
          <h3 className="brand">ShortCodeGames</h3>
        </div>
        <div className="content">
          <h1 className="title">Unlock the Secrets of Game Development with Our Source Code!</h1>
          <p className="description">
            Welcome to the gateway of game creation! If you've ever dreamed of building your own games, you're in the right place. Our platform offers you fully customizable game source code that is ready to be explored and adapted to your unique vision.
          </p>
          <div className="buttons">
            <button className="button" onClick={goToAllGames}>
              Explore All Games
            </button>
            <button className="button secondary"
              onClick={goToLearnMore}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>

      {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress style={{ display: "block", margin: "20px auto" }} />
      </Box>
      ) : (
        <CardGrid featuredProducts={featuredProducts} />
      )}
      <About
        backgroundImage="/assets/about-bg.jpg" // Replace with your background image
        contentImage="/assets/about.jpg" // Replace with your image
        title="About the"
        subtitle="ShortCodeGames"
        description="ShortCodeGames is a leading gaming platform that offers a wide range of exciting and immersive games. Our mission is to provide gamers with the best possible gaming experience through high-quality graphics, engaging storylines, and seamless gameplay. Join us and explore the world of ShortCodeGames today!"
      />
      <ContactusUs />
      <Social />
      <Footer />
    </>
  );
};

export default HomePage;
