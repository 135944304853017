import { useState, useEffect } from 'react';
import {useAuthContext} from '../context/hooks/use-auth-context'
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function GuestGuard({ children }) {
    const navigate = useNavigate();

  const { loading, authenticated, user } = useAuthContext();

  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    if (loading) {
      return;
    }

    if (authenticated) {
      user.role === "admin" ? navigate('/dashboard/games') : navigate('/');
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loading]);

  if (isChecking) {
    return <div>Loading please wait we are giving you a pleasure in Guest Guard...</div>;
  }

  return <>{children}</>;
}