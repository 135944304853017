import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import ReactPlayer from "react-player"; // For embedding YouTube videos
import CardComponent from "../cards/CardComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/hooks/use-auth-context";
import { toast } from "react-toastify";
import { deleteProduct } from "../../api/product";
import { Helmet } from "react-helmet";

const CardDetail = ({ gameData, similarItems }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();

  const handleDeleteGame = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (confirmed) {
      try {
        const response = await deleteProduct(id);
        if (response.success) {
          toast.success("Game deleted successfully!");
          navigate("/dashboard/games");
        }
      } catch (error) {
        toast.error("Failed to delete game.");
      }
    }
  };

  // Scroll to the top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]); // Re-run this effect when the route changes

  return <>
    <Helmet>
      <title>{gameData?.name}</title>
      <meta name="description" content={gameData?.description} />
      <meta name="keywords" content={`buy ${gameData?.name}, discounted ${gameData?.category?.name} codes`} />
    </Helmet>
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Game Details */}
      <Card sx={{ padding: 3, boxShadow: 3, mb: 6 }}>
        {/* Admin Delete Button */}
        {user?.role === "admin" && (
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteGame(gameData._id)}
            >
              Delete Product
            </Button>
          </Box>
        )}

        {/* Game Thumbnail */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CardMedia
            component="img"
            image={gameData?.images[0]?.url}
            alt={gameData?.name}
            sx={{ width: "100%", maxWidth: "600px", borderRadius: 2 }}
          />
        </Box>

        {/* Game Info */}
        <CardContent>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
          >
            {gameData?.name}
          </Typography>

          {/* YouTube Video */}
          {gameData?.youtube_url && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <ReactPlayer
                url={gameData.youtube_url}
                controls
                width="100%"
                maxWidth="800px"
              />
            </Box>
          )}

          {/* Game Description */}
          <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
            {gameData?.description}
          </Typography>

          {/* Game Price */}
          <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
            Price: <span style={{ color: "green" }}>${gameData?.price}</span>
          </Typography>

          {/* Additional Images */}
          {gameData?.images?.length > 1 && (
            <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 2 }}>
              {gameData.images.slice(1).map((image, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  image={image.url}
                  alt={`Game Screenshot ${index + 1}`}
                  sx={{ width: { xs: "100%", sm: "200px" }, borderRadius: 2 }}
                />
              ))}
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Similar Items Section */}
      {similarItems.length > 0 && (
        <>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
            Similar Items You May Like
          </Typography>
          <Grid container spacing={4}>
            {similarItems.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                {/* for seo */}
                <Helmet>
                  <script type="application/ld+json">
                    {`
                    {
                      "@context": "https://schema.org",
                      "@type": "Product",
                      "name": "${product.name}",
                      "price": "${product.price}",
                      "image": "${product.images[0]?.url}",
                      "url": "https://shortcodegames.com/gameDetail/${product._id}"
                    }
                  `}
                  </script>
                </Helmet>

                <div onClick={() => navigate(`/gameDetail/${product._id}`)} style={{ cursor: "pointer" }}>
                  <CardComponent
                    image={product?.images[0]?.url || ""}
                    title={product.name}
                    description={product.description}
                    price={product.price}
                    category={product.category?.name || "Unknown Category"}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  </>
};

export default CardDetail;
