import React from "react";
import { Grid, Container } from "@mui/material";
import CardComponent from "./CardComponent";
import { useNavigate } from "react-router-dom";

const CardGrid = ({ featuredProducts }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  return (
    <Container sx={{ paddingY: 4 }}>
      <Grid container spacing={3} justifyContent="flex-start">
        {featuredProducts.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index} justifyContent={"center"} alignItems={"center"}>
            <div onClick={() => navigate(`/gameDetail/${product._id}`)} style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}>
              <CardComponent
                id={product._id}
                image={product.images[0].url} // Assuming you want the first image
                title={product.name}
                description={product.description}
                price={product.price} // You can adjust as per your data
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CardGrid;
