import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Container maxWidth="sm">
        {/* Copyright */}
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ color: "white" }}
        >
          {"© "}
          {new Date().getFullYear()}
          {" ShortCodeGames. All rights reserved."}
        </Typography>
        {/* Footer Links */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 3, mb: 1 }}>
          <Link
            component={RouterLink}
            to="/terms-of-service"
            sx={{ color: "white", textDecoration: "none", fontSize: "14px" }}
          >
            Terms of Service
          </Link>
          <Link
            component={RouterLink}
            to="/privacy-policy"
            sx={{ color: "white", textDecoration: "none", fontSize: "14px" }}
          >
            Privacy Policy
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
