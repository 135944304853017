import React, { useState, useEffect } from "react";
import {
  Grid,
  Pagination,
  Typography,
  Box,
  CircularProgress,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import CardComponent from "../components/cards/CardComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCategories } from "../api/category";
import { listProduct } from "../api/product";
import { Helmet } from "react-helmet";

const AllGamesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await getAllCategories();
        console.log(res);
        setCategories(res.categories);
      } catch (error) { }
    };
    getCategories();
  }, []);

  const fetchProducts = async (queryParams) => {
    setLoading(true);
    try {
      const response = await listProduct(queryParams);
      setProducts(response.products);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search") || "";
    const categoryQuery = params.get("categoryId") || "";
    const pageQuery = parseInt(params.get("page"), 10) || 1;

    setSearch(searchQuery);
    setCategoryId(categoryQuery);
    setPage(pageQuery);

    const queryParams = `?page=${pageQuery}&limit=8${searchQuery ? `&search=${searchQuery}` : ""
      }${categoryQuery ? `&categoryId=${categoryQuery}` : ""}`;

    fetchProducts(queryParams);
  }, [location.search]);

  const handlePageChange = (event, value) => {
    setPage(value);
    updateQueryParams({ page: value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
    updateQueryParams({ search: e.target.value, page: 1 });
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
    setPage(1);
    updateQueryParams({ categoryId: e.target.value, page: 1 });
  };

  const handleClearFilters = () => {
    setSearch("");
    setCategoryId("");
    setPage(1);
    updateQueryParams({ search: "", categoryId: "", page: 1 });
  };

  const updateQueryParams = (newParams) => {
    const params = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(newParams)) {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    }
    navigate({ search: params.toString() });
  };
  const pageTitle = search
    ? `Search Results for "${search}" | ShortCode Games`
    : categoryId
      ? `Games in Category "${categories.find((c) => c._id === categoryId)?.name}" | ShortCode Games`
      : "All Games | ShortCode Games";

  const pageDescription =
    "Browse a wide selection of games available on ShortCode Games. Find your favorite categories, search for specific titles, and discover the latest additions.";

  return <>
    {/* SEO Metadata */}
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content="games, gaming, game store, buy games, ShortCode Games" />
      <meta name="author" content="ShortCode Games" />
    </Helmet>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 8 }}>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: "url('/assets/background.png')", // Set background image
          backgroundSize: "cover", // Cover the entire area
          backgroundPosition: "center",
          borderRadius: "10px", // Optional, to round the corners
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            opacity: "0.8",
            alignItems: "center",
            gap: 2,
            background: "#ffc311",
            flexWrap: "wrap",
            padding: { xs: "3rem 2rem", sm: "3rem 4rem", md: "6rem 8rem" },
          }}
        >
          <TextField
            label="Category"
            select
            value={categoryId}
            onChange={handleCategoryChange}
            variant="outlined"
            size="small"
            sx={{
              minWidth: "150px",
              maxWidth: "150px", // Maximum width for the category select
              backgroundColor: "#ffc107", // Yellow background for category select
              borderRadius: "5px", // Optional, rounded corners
              color: "white", // White label color
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // White border for input field
                },
              },
              "& .MuiInputLabel-root": {
                color: "white", // White label color
              },
              "& .MuiSelect-root": {
                color: "white", // White text for the selected item
              },
              "& .MuiMenuItem-root": {
                color: "black", // Black text for other menu items
              },
              "& .MuiSelect-select": {
                color: "white", // White text for the selected item in the select
              },
              "& .MuiSelect-outlined": {
                borderColor: "white", // White border for outlined select
              },
              "& .MuiInputBase-input": {
                color: "white", // White text for input base
              },
              "& .MuiOutlinedInput-input": {
                color: "white", // White text for outlined input
              },
              "& .MuiInputBase-inputSizeSmall": {
                fontSize: "14px", // Customize font size for small input size if necessary
              },
              "&:hover": {
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "white", // White border on hover
                },
                "& .MuiInputLabel-root": {
                  color: "white", // White label color on hover
                },
              },
              "&:focus-within": {
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "white", // White border on focus
                },
                "& .MuiInputLabel-root": {
                  color: "white", // White label color on focus
                },
              },
            }}
          >
            <MenuItem value="">All Categories</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Search Games"
            variant="outlined"
            value={search}
            onChange={handleSearchChange}
            size="small"
            sx={{
              flex: 1, // Take up remaining space
              minWidth: "200px",
              backgroundColor: "#ffc107", // Yellow background for search input
              borderRadius: "5px", // Optional, rounded corners
              color: "white", // White label color
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // White border for input field
                },
              },
              "& .MuiInputLabel-root": {
                color: "white", // White label color
              },
              "&:hover": {
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "white", // White border on hover
                },
                "& .MuiInputLabel-root": {
                  color: "white", // White label color on hover
                },
              },
              "&:focus-within": {
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "white", // White border on focus
                },
                "& .MuiInputLabel-root": {
                  color: "white", // White label color on focus
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              flexShrink: 0,
              color: "black",
              backgroundColor: "#ffffff", // Optional, set button background color
            }}
          >
            Clear Filters
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : products.length === 0 ? (
        <Box sx={{ textAlign: "center", marginTop: 4 }}>
          <Typography variant="h6">No products found</Typography>
        </Box>
      ) : (
        <>
          <Grid
            sx={{
              padding: { xs: "0 2rem", sm: "0 4rem", md: "0 8rem" },
            }}
            container spacing={4}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                <div onClick={() => navigate(`/gameDetail/${product._id}`)} style={{ cursor: "pointer" }}>
                  <CardComponent
                    id={product._id}
                    image={product?.images[0]?.url || ""}
                    title={product.name}
                    description={product.description}
                    price={product.price}
                    category={product.category.name}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  </>;
}

export default AllGamesPage;
