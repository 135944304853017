import React from "react";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";

const CardComponent = ({ id, image, title, description, platforms, category, price }) => {
  return (
    <Card
      sx={{
        maxWidth: 320,
        borderRadius: 3,
        boxShadow: "none", // Removed shadow
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          transform: "scale(1.02)",
        },
        transition: "transform 0.2s ease-in-out",
      }}
      key={id}
    >
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={image}
          alt={title}
          sx={{
            height: 250, // Increased image height
            objectFit: "cover",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            left: 10,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            padding: "5px 10px",
            borderRadius: 1,
            fontSize: "0.85rem",
          }}
        >
          {category}
        </Box>
      </Box>
      <CardContent sx={{ padding: "16px", flexGrow: 1 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            color: "text.primary",
            marginBottom: 1,
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
          {platforms}
        </Typography>
        <Typography
          variant="h6"
          color="primary"
          sx={{
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          ${price} {/* Price prominently displayed */}
        </Typography>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2, // Limits to 2 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
