import Layout from "./components/layout/layout";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login.jsx";
import GameForm from "./components/dashboard/GameForm.jsx";
import GameDetail from "./pages/GameDetail.jsx";
import { GuestGuard } from './auth/guest-guard.jsx';
import { GuardedRoute } from './auth/GuardedRoute.jsx';

import { useAuthContext } from "./context/hooks/use-auth-context.js";
import CategoryManager from "./pages/CategoryPage.jsx";
import HomePage from "./components/homePage/HomePage.js";
import AllGamesPage from "./pages/AllGamesPage.jsx";
import Policpy from "./pages/PolicyPage.jsx";
import TermsCond from "./pages/TermsCond.jsx";
import AboutUs from "./pages/About.jsx";


function App() {
  const { user } = useAuthContext();

  const getTitle = (path) => {
    switch (path) {
      case "/dashboard/games/create":
        return "Create Game";
      case "/dashboard/category":
        return "Category Manager";
      case "/dashboard/games":
        return "Games Page";
      case "/all-games":
        return "All Games";
      case "/":
        return "Home Page";
      default:
        return "";
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<GuestGuard><Login /></GuestGuard>} />
        <Route path="/gameDetail/:id" element={<GameDetail />} />
        <Route path="/" element={<HomePage />} />
        <Route element={<Layout title={getTitle(window.location.pathname)} />}>
          <Route path="/all-games" element={<AllGamesPage />} />
          <Route path="/dashboard/games/create" element={<GuardedRoute currentRole={user?.role} hasContent><GameForm /></GuardedRoute>} />
          <Route path="/dashboard/category" element={<GuardedRoute currentRole={user?.role} hasContent><CategoryManager /></GuardedRoute>} />
          <Route path="/dashboard/games" element={<GuardedRoute currentRole={user?.role} hasContent><AllGamesPage /></GuardedRoute>} />

          <Route path='/privacy-policy' element={<Policpy />} />
          <Route path='/terms-of-service' element={<TermsCond />} />
          <Route path='/about-us' element={<AboutUs />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
