import React from "react";
import ContentPage from "../components/content/Content";

const PolicyPage = () => {
  const content = `
Here’s a more detailed version of the Privacy Policy tailored to align with the About Us section of your website:  

---

**Your Privacy Matters to ShortCodeGames**  

At ShortCodeGames, we are committed to protecting your privacy while offering you the best possible gaming experience. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit and interact with our website. By using our platform, you agree to the terms outlined in this policy.  

---

### **1. Information Collection**  
We collect the following types of information to ensure a personalized and seamless gaming experience:  

- **Personal Information:** Your name, email address, and contact details, provided during account registration or customer support queries.  
- **Technical Information:** Your IP address, browser type, operating system, and device information, collected automatically to enhance platform performance.  
- **Activity Data:** Information about your interaction with our website, such as game preferences, session durations, and in-game activities, to better understand your gaming needs.  
- **Payment Information:** If you make purchases on our platform, we collect payment details securely through trusted payment gateways.  

---

### **2. How We Use Your Information**  
We use the information we collect to deliver a superior gaming experience:  

- **Enhance Services:** Tailor game recommendations and improve gameplay through personalized settings.  
- **Communication:** Send updates, newsletters, promotional offers, and important account or service-related notifications.  
- **Platform Improvements:** Analyze user behavior to optimize website functionality, game designs, and overall performance.  
- **Security and Compliance:** Protect against fraud, unauthorized access, and other malicious activities while complying with applicable laws and regulations.  

---

### **3. Information Sharing**  
At ShortCodeGames, we prioritize the confidentiality of your data. We will not sell or share your personal information with third parties except in the following cases:  

- **Service Providers:** Trusted partners who assist in operating our platform, such as payment processors, email service providers, and analytics companies, under strict confidentiality agreements.  
- **Legal Requirements:** When necessary to comply with legal obligations, enforce our terms, or protect our rights and the safety of our users.  

---

### **4. Your Rights and Choices**  
We empower you to manage your personal information effectively. You have the right to:  

- **Access Your Data:** Request a copy of the personal information we hold about you.  
- **Update or Correct Information:** Ensure your personal details are accurate and up to date.  
- **Delete Your Data:** Request the deletion of your account and associated data at any time.  
- **Opt-Out:** Unsubscribe from marketing communications or withdraw consent for data processing where applicable.  

To exercise any of these rights, please contact us at **[your contact email]**.  

---

### **5. Data Protection and Security**  
We implement industry-standard security measures to safeguard your data, including encryption, secure servers, and regular system updates. However, no online platform is completely secure, so we encourage you to take precautions, such as using strong passwords and keeping your account details confidential.  

---

### **6. Updates to This Privacy Policy**  
ShortCodeGames may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant updates through our website or email. Please review this page regularly to stay informed about how we protect your information.  

---

### **Contact Us**  
If you have any questions, concerns, or feedback about this Privacy Policy or how we handle your data, feel free to reach out to us at:  
**Email:** [your contact email]  

---

**Join ShortCodeGames today to explore a world of exciting games and immersive experiences!**  
  `;

  return <ContentPage title="Privacy Policy" content={content} />;
};

export default PolicyPage;
