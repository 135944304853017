import React from "react";
import { AppBar, Typography, Box, Link, Menu, MenuItem, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuthContext } from "../../context/hooks/use-auth-context";
import MenuIcon from "@mui/icons-material/Menu";
import { logout } from '../../api/auth';

const StyledHeader = styled(AppBar)(({ theme, backgroundImage }) => ({
  width: "100%",
  height: 300,
  background: `url(${backgroundImage}) center/cover no-repeat`,
  color: "#fff",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    height: 200, // Adjust height for smaller screens
  },
}));

export default function Header({ backgroundImage, title }) {
  const { user } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Handle Menu Open/Close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = async () => {
    logout();
    window.location.href = "/";
  }
  return (
    <StyledHeader position="static" backgroundImage={backgroundImage}>
      <Box
        sx={{
          width: "100%",
          background: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(1px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          gap: 4,
          height: "100%",
        }}
      >
        {/* Main Logo/Branding */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Typography
            variant="h4"
            component={Link}
            href="/"
            underline="none"
            color="inherit"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "2.5rem", md: "3rem" },
              padding: { xs: "0 2rem", sm: "0 4rem", md: "0 8rem" },

            }}
          >
            Short<span style={{ color: "yellow" }}>Code</span>GAMES
          </Typography>
          {/* Admin Navigation */}
          {user?.role === "admin" && (
            <Box sx={{
              marginTop: 2,
              padding: { xs: "0 2rem", sm: "0 4rem", md: "0 8rem" },

            }}>
              {/* Menu Icon */}
              <IconButton
                sx={{
                  color: "#fff", fontSize: "1rem",
                  border: 'none',
                  outline: 'none',
                }}
                aria-controls="admin-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="admin-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "admin-menu-button",
                }}
              >
                <MenuItem component={Link} href="/dashboard/games/create" onClick={handleMenuClose}>
                  Create
                </MenuItem>
                <MenuItem component={Link} href="/dashboard/games" onClick={handleMenuClose}>
                  List
                </MenuItem>
                <MenuItem component={Link} href="/dashboard/category" onClick={handleMenuClose}>
                  Category
                </MenuItem>
                <MenuItem onClick={() => {
                  // Add your logout logic here
                  handlelogout();
                  handleMenuClose();
                }}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>

        {/* Header Title */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "1.2rem", sm: "2rem", md: "2.5rem" },
            textTransform: "uppercase",
            padding: { xs: "0 2rem", sm: "0 4rem", md: "0 8rem" },

          }}
        >
          {title}
        </Typography>
      </Box>
    </StyledHeader>
  );
}
