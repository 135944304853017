/* eslint-disable */
import React, { useEffect, useState } from 'react'
import CardDetail from '../components/cardDetails/CardDetail'
import { getProductDetails } from '../api/product';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import { Helmet } from 'react-helmet';

const GameDetail = () => {
  const { id } = useParams(); // Get the card ID from URL params
  const [gameData, setGameData] = useState(null);
  const [similarItems, setSimilarItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchGameDetails = async () => {
    try {
      const response = await getProductDetails(id);
      if (response.success) {
        setGameData(response?.product);
        setSimilarItems(response?.similarItems || []);
      }
    } catch (error) {
      console.log("🚀 ~ fetchGameDetails ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDetails();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!gameData?._id) {
    return <Typography variant="h5">Game not found.</Typography>;
  }
  return <>

    <Helmet>
      <title>{`Buy ${gameData?.name} at Best Price - Game Store`}</title>
      <meta name="description" content={`Buy ${gameData?.name} with instant delivery at the best price. Discount codes for all platforms.`} />
      <meta name="keywords" content={`${gameData?.name}, game code, discounted games, gaming codes`} />
      <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "${gameData?.name}",
              "image": "${gameData?.images[0]?.url}",
              "description": "${gameData?.description}",
              "offers": {
                "@type": "Offer",
                "url": "https://shortcodegames.com/gameDetail/${gameData?._id}",
                "priceCurrency": "USD",
                "price": "${gameData?.price}",
                "priceValidUntil": "2025-12-31",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
      </script>
    </Helmet>

    <Header title={`Details-) ${gameData?.name}`} backgroundImage={gameData?.images[0]?.url} />
    <CardDetail gameData={gameData} similarItems={similarItems} />
    <Footer />
  </>
}

export default GameDetail