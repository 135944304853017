import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography, Box, MenuItem, IconButton } from '@mui/material';
import { productNew } from '../../api/product';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import { getAllCategories } from '../../api/category';
import CircularProgress from '@mui/material/CircularProgress';

const GameForm = () => {
  const [gameData, setGameData] = useState({
    name: '',
    description: '',
    price: '',
    youtubeLink: '', // New field for YouTube link
    category: '',
    images: [], // For description images
    gameFile: null, // For zip file upload
  });
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]); // To store category options
  const fileInputRef = useRef(null); // Reference to the file input field

  // Fetch categories from API when component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getAllCategories();

      setCategories(result?.categories);
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGameData({
      ...gameData,
      [name]: value,
    });
  };

  const handleDescriptionImagesChange = (e) => {
    const files = Array.from(e.target.files); // Convert to an array to allow multiple selections
    setGameData({
      ...gameData,
      images: [...gameData.images, ...files], // Add new files to existing ones
    });
  };

  const removeDescriptionImage = (index) => {
    const updatedImages = gameData.images.filter((_, i) => i !== index);
    setGameData({
      ...gameData,
      images: updatedImages,
    });
  };

  // const handleZipFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setGameData({
  //     ...gameData,
  //     gameFile: file, // Store the zip file
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!gameData.gameFile) {
    //   toast.error('Zip file is required.');
    //   return;
    // }

    if (gameData.images.length < 3) {
      toast.error('At least 3 description images are required.');
      return;
    }

    const formData = new FormData();
    formData.append('name', gameData.name);
    formData.append('description', gameData.description);
    formData.append('price', parseFloat(gameData.price));
    formData.append('youtube_url', gameData.youtubeLink);
    formData.append('category', gameData.category);

    // formData.append('gameFile', gameData.gameFile);
    gameData.images.forEach((image, index) => {
      formData.append('images', image);
    });

    try {
      setLoading(true);
      const response = await productNew(formData);
      if (!response.success) {
        toast.error(response?.message);
        return;
      }

      toast.success('Game added successfully!');
      setGameData({
        name: '',
        description: '',
        price: '',
        youtubeLink: '',
        category: '',
        images: [],
        // gameFile: null,
      });
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear the file input
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to create game.');
    }finally{
      setLoading(false);
    }
  };


  return (
    <Box sx={{ padding: '20px', marginTop: '70px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        New Game
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Toast container */}
        <ToastContainer />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          {/* Category Field */}
          <TextField
            select
            fullWidth
            label="Category"
            name="category"
            variant="outlined"
            value={gameData.category}
            onChange={handleChange}
            required
          >
            {categories.map((cat) => (
              <MenuItem key={cat._id} value={cat._id}>
                {cat.name}
              </MenuItem>
            ))}
          </TextField>

          {/* Game Name Field */}
          <TextField
            fullWidth
            label="Game Name"
            name="name"
            variant="outlined"
            value={gameData.name}
            onChange={handleChange}
            required
          />

          {/* Price Field */}
          <TextField
            fullWidth
            label="Price"
            name="price"
            variant="outlined"
            value={gameData.price}
            onChange={handleChange}
            required
          />

          {/* YouTube Link Field */}
          <TextField
            fullWidth
            label="YouTube Link"
            name="youtubeLink"
            variant="outlined"
            value={gameData.youtubeLink}
            onChange={handleChange}
          />

          {/* Game Description Field */}
          <TextField
            fullWidth
            label="Game Description"
            name="description"
            variant="outlined"
            multiline
            rows={4}
            value={gameData.description}
            onChange={handleChange}
            required
          />

          {/* Description Images Upload */}
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', gap: '5px' }}>
            <Typography variant="h6">Description Images (At least 3 required)</Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{
                  width: '300px',
                  maxWidth: '300px',
                  height: '172px',
                  border: '2px dashed blue',
                }}
                variant="outlined"
                component="label"
                startIcon={<AddPhotoAlternateIcon />}
              >
                Add Images
                <input type="file" ref={fileInputRef} multiple hidden onChange={handleDescriptionImagesChange} />
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                marginTop: 2,
              }}
            >
              {gameData.images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    width: '150px',
                    height: '150px',
                  }}
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Description Preview ${index}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => removeDescriptionImage(index)}
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>

          {/* Zip File Upload */}
          {/* <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', gap: '5px' }}>
            <Typography variant="h6">Upload Game Files (Zip)</Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{
                  width: '300px',
                  maxWidth: '300px',
                  height: '172px',
                  border: '2px dashed blue',
                }}
                variant="outlined"
                component="label"
              >
                Upload Zip File
                <input type="file" ref={fileInputRef} hidden accept=".zip" onChange={handleZipFileChange} />
              </Button>
            </Box>
            {gameData.gameFile && (
              <Box sx={{ marginTop: 2, width: '100%' }}>
                <Typography variant="body1">{gameData.gameFile.name}</Typography>
                <IconButton
                  size="small"
                  onClick={() => setGameData({ ...gameData, gameFile: null })}
                  sx={{
                    position: 'relative',
                    top: -10,
                    left: 10,
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box> */}

          {/* Submit Button */}
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={loading}>
  {loading ? <CircularProgress size={24} /> : 'Submit Game'}
</Button>
        </Box>
      </form>
    </Box>
  );
};

export default GameForm;
