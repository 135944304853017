import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import GoogleIcon from '@mui/icons-material/Google';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
// import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const Social = () => {
    return (
        <Container maxWidth="lg" sx={{ my: 6 }}>
            <Grid container spacing={3} justifyContent="center">
                {[
                    { icon: <FacebookIcon />, label: "Facebook", link: "https://web.facebook.com/people/Short-Code-Games/61566002141159/?mibextid=JRoKGi" },
                    // { icon: <TwitterIcon />, label: "Twitter" },
                    // { icon: <GoogleIcon />, label: "Google Plus" },
                    { icon: <YouTubeIcon />, label: "YouTube", link: "https://www.youtube.com/@ShortCodeGames" },
                    { icon: <InstagramIcon />, label: "Instagram", link: "https://www.instagram.com/shortcodegames?igsh=czc2NmJsbXZqenV4" },
                    // { icon: <SportsEsportsIcon />, label: "Twitch" },
                ].map((social, index) => (
                    <Grid
                        item
                        xs={4}
                        sm={2}
                        key={index}
                        sx={{ textAlign: "center", color: "#f39c12" }}
                    >
                        <a href={social.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            {social.icon}
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                                {social.label}
                            </Typography>
                        </a>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Social;