import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          toast.success("Message sent successfully!", {
            position: "top-right",
          });
          form.current.reset();
        },
        (error) => {
          toast.error("Failed to send the message. Please try again.", {
            position: "top-right",
          });
        }
      );
  };

  return (
    <Box
      sx={{
        py: 6,
        textAlign: "center",
      }}
    >
      {/* Form Header */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textTransform: "uppercase",
          mb: 4,
        }}
      >
        Contact Us
      </Typography>

      {/* Contact Form */}
      <Box
        sx={{
          background: "linear-gradient(to bottom, rgba(255,215,0,0.9), #f1c40f)",
          py: 6,
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          {/* Toast Container */}
          <ToastContainer />

          {/* Contact Form */}
          <form ref={form} onSubmit={sendEmail}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Your Name"
                  name="user_name"
                  required
                  sx={{
                    background: "rgba(255, 255, 255, 0.9)",
                    borderRadius: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Your Email"
                  name="user_email"
                  type="email"
                  required
                  sx={{
                    background: "rgba(255, 255, 255, 0.9)",
                    borderRadius: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Message"
                  name="message"
                  required
                  sx={{
                    background: "rgba(255, 255, 255, 0.9)",
                    borderRadius: 1,
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="warning"
              sx={{
                mt: 3,
                py: 1.5,
                px: 5,
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "uppercase",
                background: "#e67e22",
                "&:hover": {
                  background: "#d35400",
                },
              }}
            >
              Send Message
            </Button>
          </form>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactUs;
