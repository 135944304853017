import React from "react";
import ContentPage from "../components/content/Content";

const TermsPage = () => {
  const content = `
  Welcome to our website! By accessing or using our website, you agree to the following terms and conditions. Please read them carefully before proceeding.

  **1. Use of Site:**
  You must use our site in compliance with all applicable laws, regulations, and industry standards. Unauthorized use of this site, including but not limited to hacking, data scraping, or other malicious activities, is strictly prohibited. You agree not to interfere with the security features of the site or attempt to gain unauthorized access to any part of the website, servers, or networks.

  **2. User Accounts:**
  Some features of the website may require you to create an account. You are responsible for maintaining the confidentiality of your account information, including your password. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to terminate or suspend your account for any violation of these terms.

  **3. Intellectual Property:**
  All content on this site, including text, graphics, logos, images, and software, is the intellectual property of the website owner or its licensors. You may not copy, reproduce, distribute, or create derivative works from this content without express written permission. Trademarks, logos, and service marks displayed on the site are the property of their respective owners and may not be used without authorization.

  **4. User-Generated Content:**
  By submitting content to our website, such as reviews, comments, or other materials, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, and distribute that content in any medium. You are solely responsible for the content you submit and agree not to post anything that is unlawful, defamatory, or infringes on the rights of others.

  **5. Limitation of Liability:**
  We strive to ensure the accuracy and reliability of the information presented on our site. However, we do not guarantee that the site will be error-free, uninterrupted, or free from viruses or other harmful components. We are not liable for any damages, including direct, indirect, incidental, or consequential damages, arising from your use of or inability to use the website.

  **6. Links to Third-Party Sites:**
  Our website may contain links to third-party websites for your convenience. We are not responsible for the content, accuracy, or practices of these external sites. Your use of third-party websites is at your own risk and subject to the terms and conditions of those sites.

  **7. Privacy Policy:**
  Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and safeguard your information.

  **8. Indemnification:**
  You agree to indemnify and hold harmless the website owner, its affiliates, and employees from any claims, liabilities, damages, and expenses arising out of your use of the site or violation of these terms.

  **9. Changes to Terms:**
  We reserve the right to modify these terms at any time without prior notice. Any changes will be effective immediately upon posting on the website. Your continued use of the website constitutes your acceptance of the modified terms.

  **10. Governing Law:**
  These terms are governed by the laws of [Insert Jurisdiction]. Any disputes arising from your use of the website will be resolved exclusively in the courts of [Insert Location].

  By using our website, you acknowledge that you have read, understood, and agree to these terms and conditions. If you do not agree, please refrain from using our website.
`;


  return <ContentPage title="Terms and Conditions" content={content} />;
};

export default TermsPage;
