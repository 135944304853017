// src/api/axiosInstance.js
import axios from 'axios';
// Create an instance of axios with a base URL
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,  // Ensure this environment variable is correctly set
});

// Add a response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong!')
);

export default axiosInstance;

// src/api/endpoints.js
export const endpoints = {
  auth: {
    me: '/me',
    editProfile: (id) => `/profile/${id}`,  // Use backticks and quotes for template literals
    signIn: '/login',
    verifyEmail: '/auth/verify',
    setPasswordWithToken: '/auth/set-password',
    resendToken: '/auth/resend-verification',
    forgetPassword: '/auth/forgot-password',
  },
  products: {
    new: '/products/new',
    listingFeatured: '/products/featured',
    listAll: '/products',
    details: (id) => `/products/${id}`,  // Use backticks and quotes for template literals
    delete: (id) => `/products/${id}`,
  },
  category: {
    new: '/category',
    all: 'category',
    edit: (id) => `/category/${id}`,
    details: (id) => `/category/${id}`,
    remove: (id) => `/category/${id}`,
  }
};
