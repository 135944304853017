import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBackground = styled(Box)(({ backgroundImage }) => ({
  width: "100%",
  minHeight: '700px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const StyledContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: 'inherit',
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(4),
  width: "100%",
  padding: theme.spacing(4, 0, 4, 0),
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const About = ({ backgroundImage, contentImage, title, subtitle, description }) => {
  const navigateToAboutUs = () => {
    window.location.href = "/about-us";
  };

  return (
    <StyledBackground backgroundImage={backgroundImage}>
      <StyledContentBox>
        {/* Left Side Image */}
        <Box
          component="img"
          src={contentImage}
          alt="About"
          sx={{
            width: "100%",
            maxWidth: { xs: "300px", sm: "550px", md: "500px" },
            height: "auto",
            borderRadius: "16px",
            padding: { xs: "0 0 0 0rem", sm: "0 0 0 0", md: "0 0 0 8rem" },
          }}
        />

        {/* Right Side Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
            maxWidth: { xs: "300px", sm: "550px", md: "500px" },
            minWidth: 300,
            borderRadius: "16px",
            padding: { xs: "0 2rem 0 2rem", sm: "0 4rem 0 4rem", md: "0 8rem 0 2rem" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: 2,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "yellow",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: 2,
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              marginBottom: 3,
              lineHeight: 1.6,
            }}
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToAboutUs}
            sx={{
              backgroundColor: "yellow",
              color: "black",
              fontWeight: "bold",
              padding: "8px 24px",
              "&:hover": {
                backgroundColor: "#ffd700",
              },
            }}
          >
            Read More
          </Button>
        </Box>
      </StyledContentBox>
    </StyledBackground>
  );
};

export default About;
