import React from "react";
import ContentPage from "../components/content/Content";

const AboutUsPage = () => {
  const content = `
  Welcome to our platform! We are thrilled to have you here and are committed to providing exceptional services tailored to your needs. Here's a bit more about who we are and what drives us:

  **🌟 Our Mission:**
  Our mission is to deliver a seamless, engaging, and intuitive user experience. We aim to provide high-quality services that help you achieve your goals efficiently and effectively. Every feature we build and every decision we make is focused on empowering our users to succeed.

  **🌍 Our Vision:**
  Our vision is to become a leading platform that inspires and supports individuals and organizations worldwide. We aspire to create an environment where innovation meets simplicity, helping you focus on what truly matters.

  **💡 Our Values:**
  - **Innovation:** We strive to think outside the box, continuously enhancing our platform to serve you better.
  - **Integrity:** Trust and transparency are the cornerstones of our relationships with users.
  - **Excellence:** We are dedicated to maintaining the highest standards in everything we do.

  **🌈 What We Offer:**
  - A user-friendly platform designed with you in mind.
  - Regular updates to ensure a modern and seamless experience.
  - A supportive team ready to assist you whenever you need it.

  **🤝 Join Us on This Journey:**
  We believe in building a community that grows together. Whether you’re here to explore, learn, or achieve something incredible, we’re here to support you every step of the way.

  **📸 Suggested Images:**
  - A hero image showcasing happy people using a digital platform on various devices (laptop, phone, tablet).
  - A visually appealing mission statement with icons representing goals and achievements.
  - A graphic displaying the core values with symbols like a lightbulb (innovation), handshake (integrity), and trophy (excellence).
  - An image of a team collaborating, symbolizing community and support.

  **✨ Thank You:**
  Thank you for choosing us as your platform. We are excited to have you join this journey and can’t wait to see what we can achieve together!
`;


  return <ContentPage title="About Us" content={content} />;
};

export default AboutUsPage;
